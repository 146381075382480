import { Box, Button, Chip, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteUserAccMutation } from "../../../store/feature/service/UserAccountApiSlice";
import { useEffect } from "react";

const UserAccountTable = ({ data, params, setParams }: any) => {
  const navigate = useNavigate();
  const { usrAcc } = useAppSelector((state: RootState) => state.role);
  const [deleteUser, { isSuccess, isError }] = useDeleteUserAccMutation();

  const handleEdit = (userId: string, rowData: any) => {
    navigate(`/user-account/edit/${userId}`, { state: rowData });
  };

  const handleChangePassword = (email: string) => {
    navigate(`/user-account/change-password-jwt`, { state: email });
  };

  const handleDelete = (userId: string) => {
    Swal.fire({
      title: "Delete Confirmation",
      text: "Are you sure you want to delete this user? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId);
      }
    });
  };

  const columns: GridColDef[] = [
    { field: "userId", headerName: "User ID", flex: 1, minWidth: 250 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 250 },
    { field: "lastLogin", headerName: "Last Login", flex: 1, minWidth: 250 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridCellParams) => {
        const status = params.row.status?.toLowerCase();
        if (status === "active") {
          return <Chip label="Active" color="success" variant="outlined" />;
        } else if (status === "inactive") {
          return <Chip label="Inactive" color="warning" variant="outlined" />;
        } else if (status === "deleted") {
          return <Chip label="Deleted" color="error" variant="outlined" />;
        }
        return null;
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <Stack spacing={2} direction="row">
          {usrAcc.update && (
            <>
              <Button variant="contained" onClick={() => handleEdit(params.row.userId, params.row)}>
                Update
              </Button>
              <Button variant="contained" color="info" onClick={() => handleChangePassword(params.row.email)}>
                Change Password
              </Button>
            </>
          )}
          {usrAcc.delete && (
            <Button variant="contained" color="error" onClick={() => handleDelete(params.row.userId)}>
              Delete
            </Button>
          )}
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    if (isSuccess || isError) {
      Swal.fire({
        icon: isSuccess ? "success" : "error",
        title: `${isSuccess ? "Success" : "Error"} Delete`,
        text: `${isSuccess ? "Successfully deleted" : "Error deleting"} user account`,
        confirmButtonColor: "#051438",
      });
    }
  }, [isSuccess, isError]);

  const rows = data?.data?.map((row: any, index: number) => ({
    id: index,
    userId: row.adm_user_uid,
    email: row.adm_user_email,
    lastLogin: row.adm_last_login,
    status: row.adm_user_status,
    firstName: row.adm_user_firstname,
    lastName: row.adm_user_lastname,
    phoneNumber: row.adm_user_mobile,
    accessGroup: row.adm_role_uid,
  })) || [];

  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data.total_records}
      />
    </>
  );
};

export default UserAccountTable;
