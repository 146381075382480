// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const logEventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getParamDefUID: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/ddd-paramdef/get-param-def-by-ddd-uid/${params}`,
        };
      },
      providesTags: ["GetParamDefUID"],
    }),
    getParamDefByUID: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/ddd-paramdef/get-param-def-by-ddd-uid/${params}`,
        };
      },
      invalidatesTags: ["GetParamDefByUID"],
    }),
    // getDddFieldByUid: builder.mutation<any, any>({
    //   query: (params) => {
    //     return {
    //       url: `/ddd-field/detail-ddd-by-uid/${params}`,
    //       method: "GET",
    //     };
    //   },
    //   invalidatesTags: ["GetDddFieldByUid"],
    // }),
    createParamDef: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/ddd-paramdef/create-param-def`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateParamDef"],
    }),
    updateParamDef: builder.mutation<any, any>({
      query: ({uid, body}) => {
        return {
          url: `/ddd-paramdef/update-param-def/${uid}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateParamDef"],
    }),
    deleteParamDef: builder.mutation<any, any>({
      query: (uid) => {
        return {
          url: `/ddd-paramdef/delete-param-def/${uid}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeleteParamDef"],
    }),
    createParamRef: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `ddd-paramspec/create-param-field-spec`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateParamRef"],
    }),
    deleteParamSpec: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/ddd-paramspec/delete-param-field-spec/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeleteParamSpec"],
    }),
    createParamSpec: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/ddd-paramspec/create-param-field-spec`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateParamSpec", "GetParamDefUID"],
    }),
    updateParamSpec: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/ddd-paramspec/update-param-field-spec`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateParamSpec"],
    }),
  }),
});

export const {
  useCreateParamDefMutation,
  useUpdateParamDefMutation,
  useGetParamDefUIDQuery,
  useGetParamDefByUIDMutation,
  useDeleteParamSpecMutation,
  useCreateParamSpecMutation,
  useUpdateParamSpecMutation,
  useDeleteParamDefMutation
} = logEventsApiSlice;
